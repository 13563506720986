<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Страница редактирования профиля" />
      <ProfileForm :initialValues="profile" />
    </div>
    <div v-else>Нет данных для этого пользователя</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import ProfileForm from '@/components/forms/ProfileForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

import { loadPageData } from '@/mixins/loadPageData'
import { routeParams } from '@/mixins/routeParams'

import { API_PROFILES } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю данные пользователя',
    }
  },
  components: {
    ProfileForm,
    PageHeader,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_PROFILES}/${this.profileId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для пользователя: ${this.profileId}`
    },
    profile() {
      return this.pageData
    },
  },
}
</script>
